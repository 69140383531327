<template>
  <Dialog>
    <template v-if="product.productSubscription.discount" #headline-suffix
      ><SubscripitonDiscountBadge
        :discount="product.productSubscription.discount"
    /></template>
    <template #default>
      <div v-if="productSubscriptionCounter.counter === 0">
        <div
          v-if="product.productSubscription.discount"
          class="font-bold pt-md font-deco-alt text-md md:text-lg mx-md"
        >
          {{
            t('shop.subscription.buyBox.discount', {
              discount: product.productSubscription.discount,
            })
          }}
        </div>
        <BulletList
          space-between-list-items="xs"
          :list-type="EListType.CheckMarksPrimary"
          :list-items="tList('shop.aboBox.productSubscription.list')"
          class="text-sm font-text md:text-md m-md"
        />
        <ContentSeparator class="relative mt-lg" />
      </div>

      <div class="p-md">
        <div class="product-grid">
          <div class="flex product-grid__image">
            <div class="relative w-[65px] sm:w-[150px] h-auto shrink-0">
              <ProductImage
                :image-url="product.media?.cover"
                :alt-text="product.media?.alt"
                class="w-[65px] h-[65px] sm:h-[120px] sm:w-[120px] object-scale-down mr-auto"
              />
            </div>
          </div>
          <div class="product-grid__productInfo">
            <div class="font-bold text-md md:text-lg font-deco-alt">
              {{ product.name }}
            </div>
            <div class="text-sm md:text-md">
              <span v-if="product.variantName">
                {{ product.variantName }}
                <span v-if="product.manufacturer"> | </span>
              </span>
              <span v-if="product.manufacturer">
                {{ product.manufacturer.name }}
              </span>
            </div>
            <div class="text-sm md:text-md">
              <span
                >{{ t('shop.cart.productFinder.itemNumber') }}
                {{ product.productNumber }}</span
              >
            </div>
          </div>
          <div class="text-sm product-grid__price md:text-md">
            <SubscripitonPrice
              :product="product"
              :dialog="true"
              :quantity="quantity"
            />
          </div>
        </div>
        <ContentSeparator class="relative mt-md" />
        <div class="productEdit-grid">
          <div class="productEdit-grid__quantity">
            <div class="flex flex-col w-full flex-nowrap">
              <span class="font-bold">{{ t('shop.cart.general.amount') }}</span>
              <QuantityPicker
                :key="quantity"
                v-model="quantity"
                class="max-w-[167px] w-full"
                :lower-limit="product.minPurchase"
                :upper-limit="product.maxPurchase"
                :step-size="product.purchaseSteps"
                :debounce-emit="500"
                @update:model-value="(nv) => (quantity = nv)"
                @click.prevent
              />
            </div>
          </div>
          <div class="productEdit-grid__interval">
            <Dropdown
              id="aboInterval"
              v-model="interval"
              :options="deliveryIntervals"
              name="aboInterval"
              :label="t('shop.pdp.productSubscription.aboInterval')"
            ></Dropdown>
          </div>
          <div class="productEdit-grid__delivery">
            <Dropdown
              id="aboDeliveryDay"
              v-model="selectedDeliveryDay"
              :options="deliveryDays"
              name="aboDeliveryDay"
              :label="t('shop.pdp.productSubscription.deliveryDay')"
            >
              <template #info>
                {{ t('shop.pdp.productSubscription.edit.info') }}
              </template>
            </Dropdown>
          </div>
          <div class="productEdit-grid__infoBanner">
            <div
              class="flex h-full text-sm px-sm md:text-md bg-background-base rounded-alt-md"
            >
              <div class="flex items-center icon-box bg-grey-light">
                <FaIcon
                  icon-class="fal fa-truck-fast"
                  classes="text-black mx-xs w-sm h-sm md:h-[20px] md:w-[20px]"
                />
              </div>
              <div class="my-xs mx-sm">
                <div>
                  {{ t('shop.productSubscription.reactivate.firstOrder') }}
                  <span class="font-bold text-status-success-dark">{{
                    t('shop.productSubscription.reactivate.hint')
                  }}</span>
                </div>
                <div>
                  {{ t('shop.productSubscription.reactivate.secondOrder') }}
                  <span class="font-bold text-status-success-dark">{{
                    nextOrderDate
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddToCart
          label="shop.productList.addToCart"
          class="!w-full !h-fit mt-sm"
          @click="onSubmit(product.id, quantity, interval, selectedDeliveryDay)"
        />
        <div
          class="flex items-center justify-center w-full gap-2 cursor-pointer text-primary-base mt-md"
          @click="dialogStore.closeDialog()"
        >
          <span class="anim__underline-link anim-link">
            {{ t('formFields.form.cancel') }}
          </span>
        </div>
      </div>
    </template>
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from '@/components/dialog/components/dialogFrame/local/dialogFrame.vue';
import { useDialogStore } from '@/stores/useDialogStore';
import { Dropdown, QuantityPicker, ProductImage } from '@/complib';
import FaIcon from '@/components/fa-icon.vue';
import { useSubscriptionBox } from '@/components/page/product/components/buyBox/components/options/subscription/useSubscriptionBox';
import ContentSeparator from '@/components/block/seperator/contentSeparator.vue';
import AddToCart from '@/components/components/shop/inputs/add-to-cart.vue';
import { useProductSubscriptionActivate } from '@/components/dialog/productSubscriptionActivate/useProductSubscriptionActivate';
import type { OrderAlarm } from '@/server/transformers/shop/orderAlarmTransformer';
import BulletList from '@/components/components/core/bullet-list/bullet-list.vue';
import { EListType } from '@/@types/bullet-list';
import { useUserContext } from '@/stores/useUserContext';
import { useSessionStore } from '~/stores/useSessionStore';
import { EDeliveryDaysValues } from '@/@types/product-subscription';
import SubscripitonDiscountBadge from '@/components/page/product/components/buyBox/components/options/subscription/components/subscriptionDiscountBadge.vue';
import SubscripitonPrice from '@/components/page/product/components/buyBox/components/options/subscription/components/subscription-price.vue';

const { t, tList } = useTrans();
const userContext = useUserContext();
const session = useSessionStore();
const dialogStore = useDialogStore();
dialogStore.setHeadline(t('shop.productSubscription.activate.title'));
const data = dialogStore.getDialogData<{
  orderAlarm: OrderAlarm;
}>();

const {
  product,
  quantity,
  interval,
  productSubscriptionCounter,
  getNewDeliveryDate,
  onSubmit,
} = await useProductSubscriptionActivate(data.orderAlarm);

const { deliveryIntervals, deliveryDays } = useSubscriptionBox({
  product: product.value,
});

const selectedDeliveryDay =
  session.isLoggedIn && userContext.initialized && userContext.accountData
    ? ref(userContext.accountData.desiredDeliveryDay.toString())
    : ref(EDeliveryDaysValues.WEDNESDAY);

const nextOrderDate = ref(
  await getNewDeliveryDate(interval.value, selectedDeliveryDay.value),
);

watch(
  () => [interval.value, selectedDeliveryDay.value],
  async () => {
    const newDate = await getNewDeliveryDate(
      interval.value,
      selectedDeliveryDay.value,
    );
    nextOrderDate.value = newDate;
  },
);
</script>
<style scoped lang="postcss">
.product-grid {
  width: 100%;
  display: grid;
  grid-template-areas:
    'image productInfo'
    'image price';

  grid-template-columns: 70px 1fr;
  @apply overflow-visible;
  & > div {
    @apply flex flex-col;
  }
}

.productEdit-grid {
  width: 100%;
  display: grid;
  grid-template-areas:
    'quantity quantity'
    'interval interval'
    'delivery delivery'
    'infoBanner infoBanner';
  grid-template-columns: 70px 1fr;
  @apply overflow-visible;
  & > div {
    @apply flex flex-col;
  }
}
.product-grid__image {
  grid-area: image;
  @apply mr-sm mt-2xs;
}
.product-grid__productInfo {
  grid-area: productInfo;
  @apply pl-sm;
}
.productEdit-grid__interval {
  grid-area: interval;
  @apply my-sm;
}
.productEdit-grid__quantity {
  grid-area: quantity;
  @apply my-sm;
}
.productEdit-grid__delivery {
  grid-area: delivery;
}

.productEdit-grid__infoBanner {
  grid-area: infoBanner;
  @apply my-sm;
}

.product-grid__price {
  grid-area: price;
  @apply items-start my-sm pl-sm  justify-center;
}

@media (min-width: 960px) {
  .product-grid {
    width: 100%;
    display: grid;
    grid-template-areas:
      'image productInfo'
      'image price';

    grid-template-columns: 150px;
    @apply overflow-visible;
    & > div {
      @apply flex flex-col;
    }
  }

  .productEdit-grid {
    width: 100%;
    display: grid;
    grid-template-areas:
      'quantity interval'
      'delivery delivery'
      'infoBanner infoBanner';
    grid-template-columns: 200px;
    @apply overflow-visible;
    & > div {
      @apply flex flex-col;
    }
  }

  .product-grid__image {
    grid-area: image;
    @apply mr-sm mt-2xs;
  }
  .product-grid__productInfo {
    grid-area: productInfo;
  }
  .productEdit-grid__quantity {
    grid-area: quantity;
    @apply mb-sm mt-0;
  }

  .productEdit-grid__interval {
    grid-area: interval;
    @apply mb-sm mt-0 pl-sm;
  }
  .product-gridEdit__delivery {
    grid-area: delivery;
  }

  .product-gridEdit__infoBanner {
    grid-area: infoBanner;
    @apply rounded-alt-md my-sm;
  }
  .product-grid__price {
    grid-area: price;
    @apply items-start my-sm  justify-center;
  }
}
</style>
