import { useSecureSessionPost } from '~/composables/dataFetching/genericFetchers';
import { DialogResponses, useDialogStore } from '@/stores/useDialogStore';
import { EDeliveryIntervalValues } from '@/@types/product-subscription';
import type { OrderAlarm } from '@/server/transformers/shop/orderAlarmTransformer';
import type { ProductSubscriptionCounter } from '@/server/gateway/connections/shopware/loaders/loadProductSubscriptionTotalCounter';
import type { NewDeliveryDate } from '@/server/gateway/connections/shopware/loaders/loadNewDeliveryDate';
import { useCartStore } from '~/stores/useCart';
import { handleLoadingError } from '~/utils/handleLoadingError';

export async function useProductSubscriptionActivate(orderAlarm: OrderAlarm) {
  const intervalMap = new Map<number, string>([
    [2, EDeliveryIntervalValues.TWO_WEEKS],
    [3, EDeliveryIntervalValues.THREE_WEEKS],
    [4, EDeliveryIntervalValues.MONTHLY],
    [5, EDeliveryIntervalValues.FIVE_WEEKS],
    [6, EDeliveryIntervalValues.SIX_WEEKS],
    [8, EDeliveryIntervalValues.TWO_MONTHS],
    [12, EDeliveryIntervalValues.QUARTERLY],
    [24, EDeliveryIntervalValues.HALFYEARLY],
    [36, EDeliveryIntervalValues.NINE_MONTHS],
  ]);
  const site = useSiteIdent();
  const product = ref(orderAlarm.product);
  const quantity = ref(orderAlarm.packsPerWeek);
  const interval = ref(
    intervalMap.get(
      orderAlarm.selectedOrderInterval ?? orderAlarm.recommendedOrderInterval,
    ),
  );

  const productSubscriptionCounter = await (async () => {
    try {
      const result = await useSecureSessionPost<ProductSubscriptionCounter>(
        `/api/${site}/shop/product/productSubscription/counter`,
      );
      if (!result?.counter) {
        return {
          counter: 0,
        };
      }
      return result;
    } catch (e: any) {
      handleLoadingError(e);
      return {
        counter: 0,
      };
    }
  })();

  async function getNewDeliveryDate(
    interval: string,
    desiredDeliveryDay: string,
  ) {
    try {
      const deliveryDay: number = +desiredDeliveryDay;
      const response = await useSecureSessionPost<NewDeliveryDate>(
        `/api/${site}/shop/product/productSubscription/deliveryDate/load`,
        {
          interval: interval,
          desiredDeliveryDay: deliveryDay,
        },
      );
      return response?.date;
    } catch (e: any) {
      handleLoadingError(e);
      return null;
    }
  }

  async function onSubmit(
    productId: string,
    quantity: number,
    interval: string,
    desiredDeliveryDay: string,
  ) {
    const { addProductSubscription } = useCartStore();
    const dialogStore = useDialogStore();
    const deliveryDay: number = +desiredDeliveryDay;
    addProductSubscription(productId, quantity, deliveryDay, interval);
    dialogStore.closeDialog(DialogResponses.SUCCESS);
  }

  return {
    product,
    quantity,
    interval,
    productSubscriptionCounter,
    getNewDeliveryDate,
    onSubmit,
  };
}
